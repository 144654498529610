@import "front-partials/1-settings";//include custom bootstrap variables overrides
@import "front-partials/2-tools";//mixins and other tools. bootstrap included here
@import "front-partials/3-generic";
@import "front-partials/4-elements";
@import "front-partials/5-objects";
@import "front-partials/6-components-mb-common";//common components across mercabarna projects
@import "front-partials/6-components";
@import "front-partials/7-utilities";

.subir {
    padding: 5px 10px;
    background: #D2CFE3;
    color:#007A3D ;
    border:0px solid #fff;
    cursor: pointer;
    text-align: center;
	padding-top: 7px;
	border-radius: 3px;
	overflow: hidden;
}
@media only screen and (min-width: 992px) {
  .subir {
      height: 40px;
  }
}

.subir:hover {
    color:#fff;
    background: #007A3D ;
}

.con_archivo, .con_archivo:hover {
	background: white !important;
	border: 1px solid #D2CFE3;
	color: #D2CFE3 !important;
}

.eliminar {
	display: none;
	color: #007A3D ;
	font-weight: bold;
	cursor: pointer;
}