// mercabarna fonts
// ------------------------------------
@font-face {
    font-family: 'Gotham-Bold';
    src: url('../../fonts/Gotham-Bold.eot');
    src: url('../../fonts/Gotham-Bold.woff') format('woff'), url('../../fonts/Gotham-Bold.ttf') format('truetype')
}

@font-face {
    font-family: 'Gotham-Book';
    src: url('../../fonts/Gotham-Book.eot');
    src: url('../../fonts/Gotham-Book.woff') format('woff'), url('../../fonts/Gotham-Book.ttf') format('truetype')
}

@font-face {
    font-family: 'Gotham-Light';
    src: url('../../fonts/Gotham-Light.eot');
    src: url('../../fonts/Gotham-Light.woff') format('woff'), url('../../fonts/Gotham-Light.ttf') format('truetype')
}

@font-face {
    font-family: 'Gotham-Medium';
    src: url('../../fonts/Gotham-Medium.eot');
    src: url('../../fonts/Gotham-Medium.woff') format('woff'), url('../../fonts/Gotham-Medium.ttf') format('truetype')
}

@font-face {
    font-family: 'Gotham-Ultra';
    src: url('../../fonts/Gotham-Ultra.eot');
    src: url('../../fonts/Gotham-Ultra.woff') format('woff'), url('../../fonts/Gotham-Ultra.ttf') format('truetype')
}

// font family definitions
// ------------------------------------
$font-family-gotham-bold: "Gotham-Bold";
$font-family-gotham-book: "Gotham-Book";
$font-family-gotham-light: "Gotham-Light";
//$font-family-gotham-medium: "Gotham-Medium";
//$font-family-gotham-ultra: "Gotham-Ultra";

// color definitions
// ------------------------------------
$color-dark-blue-mb: #362783;
$color-dark-gray-2-mb: #707070; //text recibir codigo acceso - hamburguer
$color-dark-gray-mb: #9D9D9D; //placeholder text
$color-light-gray-mb: #E2E2E2; //from-control border
$color-light-gray-bg-mb: rgba(0,0,0,0.06);







// custom bootstrap variables overrides
// ------------------------------------

// Color system
// ------------

$white:#fff;
// TODO define grays mb
$black:#000;
$colors: (
    "dark-gray-2": $color-dark-gray-2-mb,
    "dark-gray": $color-dark-gray-mb,
    "light-gray": $color-light-gray-mb,
    "light-gray-bg": $color-light-gray-bg-mb,
);

// Theme colors
$primary: #007A3D;//$color-dark-blue-mb;
$secondary: $white;
$light: $white;
$dark: $black;
$theme-colors: (
    "faq-bg-mb": #E7F5F3,
    "blue-mb": #008BD2,
    "green-mb": #52AE32
);




// Options
//
// Quickly modify global styling by enabling or disabling optional features.
// -------------------------------------------------------------------------


// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.
// -----------------------------------------------------------------------------
// Spacers
$spacer: 1rem;
$spacers: ( 
    6: ($spacer * 4),
    7: ($spacer * 5),
    8: ($spacer * 6),
    10: ($spacer * 18)
);

// Body
//
// Settings for the `<body>` element.
// ----------------------------------
$body-color: $dark;

// Links
//
// Style anchor elements.
// ----------------------
$link-color: $color-dark-gray-2-mb;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
// -------------------------------------------------------------
$grid-breakpoints: (
  xs: 0,
  xs2: 334px,
  xs1: 388px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.
// -------------------------------------------------------------
$container-max-widths: (
  xs2: 323px,
  xs1: 388px,
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
);

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:                12 !default;
$grid-gutter-width:           30px !default;
$grid-row-columns:            6 !default;

// Components
//
// Define common padding and border radius sizes and more.

// Typography
//
// Fonts
// Font, line-height, and color for body text, headings, and more.
// -----------------------------------------------------------------------------

$font-family-base: $font-family-gotham-book;
$font-family-light: $font-family-gotham-light;
$font-family-bold: $font-family-gotham-bold;
$font-size-base: 0.9375rem; //15px
$line-height-base: 1.6; //24px


// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.
// -----------------------------------------------------------------------------

// Forms

$login-input-bg: rgba(0,0,0,0.06);
$input-bg: $white;

$input-placeholder-color: $color-dark-gray-mb;
//$input-border-color: theme-color("primary");

// Navbar
// -------------------------------------------------------------

$navbar-light-color: $black;

// Navs
$navbar-light-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#{$navbar-light-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='1' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
