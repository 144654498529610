// Header
// ----------------------------
.layout-public {
  min-width: (268/16)*1rem;
  .navbar {
    z-index: 1020;
  }
}
.active-lang {
  margin-top: 0.5 * $spacer;
  transform: translate(0, -0.6rem);
}
.active-lang-text {
  background-color: theme-color("primary");
  border-radius: $border-radius;
  padding: $input-btn-padding-y-sm;
}
.active-lang-arrow {
  background-color: theme-color("primary");
  width: 0.6rem;
  height: 0.6rem;
  position: absolute;
  top: 80%;
  left: 40%;
  transform: rotate(45deg);
}
li.nav-item.active-lang {
  transform: translate(0, 0);
  margin-top: 4px;
}
li.nav-item .active-lang-arrow {
  top: 68%
}
#overlay-navbar {
  .mobile-menu {
    padding-top: 0;
  }
  z-index: 1500;
  opacity: 0;
  transition: opacity 2s;
}
#overlay-navbar[style="display: block;"] {
  opacity: 1;
}

.active-lang-text {
  text-transform: uppercase;
}

// Menu
// ----------------------------
.layout-public,
.layout-user-area {
  .mb-menu {
    .nav-separator .nav-link::before {
      content: "|";
      padding-right: $spacer;
    }
    .nav-separation {
      margin-left: 2 * $spacer;
    }
  }
}
.mb-menu {
  text-transform: lowercase;

}
.mb-menu .lang-link {
  text-transform: uppercase;
}
.mb-gradient-link {
  background-image: linear-gradient(to right, theme-color("green-mb") , theme-color("blue-mb"));
  border-radius: 4px;
  margin-left: $spacer;
  margin-right: $spacer;
}
.navbar-light .navbar-nav .nav-link.mb-gradient-link {
  color: #fff;
}

// Footer navigation
// ----------------------------
.site-footer-navigation {
  a.nav-link:hover {
    text-decoration: underline;
  }
}
// login form
// ----------------------------
.login-wrap {
  .alert-success,
  .invalid-feedback {
    position: relative;
    z-index: 1200;
  }
}


// Buttons
// ----------------------------
.btn-mb-gradient {
  background-image: linear-gradient(to right, theme-color("green-mb") , theme-color("blue-mb"));
  margin: 16px 0;
}
.btn-mb-gradient-overlay {
  margin: 16px 0;
}
.navbar-light .navbar-nav .btn-mb-gradient .nav-link {
  color: $white;
}
.btn-mb-lg,
.navbar-light .navbar-nav .btn-mb-gradient .nav-link,
.navbar-light .navbar-nav .btn-mb-gradient-overlay .nav-link {
  font: normal 1.125rem/1.375rem $font-family-gotham-bold;
  text-transform: lowercase;
}
.btn-mb-lg {
  line-height: 1.5;
  font-size: 1.375rem;
}

// Home page
// ----------------------------
.main-cover-section,
.markets-section,
.shape-img {
  position: relative;
}
.markets-section {
  overflow: hidden;
}
.main-cover-section {
  overflow: hidden;
  background-image: url("/images/biomarket-3.jpg");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  h1 {
    font-family: $font-family-gotham-bold;
    color: $white;
    //padding: 64px 12px;
  }
  a {
    color: $white;
  }
  z-index: 800;
}
.cover-shape {
  position: absolute;
}
.cover-shape-141 {
  display: none;
}
.cover-shape-140 {
  bottom: 0;
  left: 0;
  width: 1600px;
  height: 600px;
  transform: rotate(-24deg) translate(-100px, 80px);
  opacity: 0.6;
  background-image: linear-gradient(to right, rgba(82, 174, 50, 0.8) , rgba(200, 212, 0, 0.8));
  z-index: 840;
}
.cover-shape-139 {
  bottom: 0;
  left: 0;
  width: 1200px;
  height: 500px;
  transform: rotate(62deg) translate(150px, 800px);
  background-color: #179D8C;
  z-index: 850;
}
.cover-shape-142 {
  bottom: 0;
  right: 0;
  width: 1200px;
  height: 500px;
  transform: rotate(-46deg) translate(-100px, 700px);
  background-color: #C8D400;
  z-index: 860
}
.cover-shape-143 {
  display: none;
}
.cover-shape-138 {
  top: 0;
  left: 0;
  width: 1200px;
  height: 1000px;
  background-image: linear-gradient(to top, rgba(0, 122, 61, 0.8) , rgba(0, 122, 61, 0.3));
}
@include media-breakpoint-up(lg) {
  .cover-shape-141 {
    display: block;
    top: 0;
    left: 0;
    height: 300px;
    width: 50%;
    transform: rotate(-18deg) translate(-100px, -260px);
    background-color: #179D8C;
    z-index: 860;
  }
  .cover-shape-140 {
    bottom: 0;
    left: 0;
    height: 800px;
    width: 1940px;
    transform: rotate(72deg) translate(-100px, 800px);
    opacity: 0.6;
    background-image: linear-gradient(to right, rgba(82, 174, 50, 0.6) , rgba(200, 212, 0, 0.6));
    z-index: 840;
  }
  .cover-shape-139 {
    bottom: 0;
    left: 0;
    width: 60%;
    height: 400px;
    transform: rotate(40deg) translate(-100px, 400px);
    background-color: #C8D400;
    z-index: 850;
  }
  .cover-shape-142 {
    bottom: 0;
    right: 0;
    width: 50%;
    height: 350px;
    transform: rotate(-16deg) translate(100px, 360px);
    background-color: #179D8C;
  }
  .cover-shape-143 {
    display: block;
    top: 0;
    right: 0;
    width: 60%;
    height: 300px;
    transform: rotate(14deg) translate(140px, -320px);
    background-color: #C8D400;
  }
  .cover-shape-138 {
    top: 0;
    left: 0;
    width: 50%;
    height: 1000px;
    background-image: linear-gradient(to right, rgba(0, 122, 61, 0.6) , rgba(0, 122, 61, 0.1),
    rgba(0, 122, 61, 0));
  }
}
.grup-961 {
  overflow: hidden;
  top: 24px;
  left: -168px;
  width: 230px;
  height: 140px;
}
.grup-962 {
  overflow: hidden;
  top: 24px;
  right: -160px;
  width: 230px;
  height: 140px;
}
.cover-shape-136 {
  top: 0;
  right: 0;
  width: 230px;
  height: 140px;
  opacity: 0.7;
  background-color: #C8D400;
  z-index: 840;
}
.cover-shape-137 {
  top: 0;
  right: 0;
  width: 500px;
  height: 280px;
  transform: rotate(18deg) translate(0px, 0px);
  background-color: #52AE32;
  z-index: 840;
  opacity: 0.5;
}
.cover-shape-135 {
  top: 0;
  right: 0;
  width: 500px;
  height: 280px;
  transform: rotate(42deg) translate(0px, 50px);
  background-color: #52AE32;
  z-index: 840;
  opacity: 0.5;
}
@include media-breakpoint-up(md) {
  .grup-961 {
    top: 1.7 * 24px;
    left: -1.7 * 168px;
    width: 1.7 * 230px;
    height: 1.7 * 140px;
  }
  .grup-962 {
    top: 1.7 * 24px;
    right: 1.7 * -160px;
    width: 1.7 * 230px;
    height: 1.7 * 140px;
  }
  .cover-shape-136 {
    width: 1.7 * 230px;
    height: 1.7 * 140px;
  }
  .cover-shape-137 {
    width: 1.7 * 500px;
    height: 1.7 * 280px;
  }
  .cover-shape-135 {
    width: 1.7 * 500px;
    height: 1.7 * 280px;
  }
}
.markets-section {
  background-color: #fff;
  z-index: 800;
}
.mb-title {
  z-index: 1020;
}
.faq-section {
  position: relative;
  overflow: hidden;
}
.grup-970 {
  top: 0;
  left: calc(50vw - 0.5*(268/16)*1rem);
  width: (268/16)*1rem;
  height: 0.2*(268/16)*1rem;
  overflow: hidden;
}
.cover-shape-155 {
  top: -180px;
  left: 0;
  background-color: #fff;
  width: (268/16)*1rem;
  height: (268/16)*1rem;
  transform: rotate(-45deg) skew(30deg, 30deg) translate(0px, 0px);
  z-index: 1020;
}
.grup-971 {
  top: -0.9px;
  left: calc(50vw - 0.5*(268/16)*1rem);
  width: (268/16)*1rem;
  height: 0.2*(268/16)*1rem;
  overflow: hidden;
  .cover-shape-155 {
    background-color: theme-color("faq-bg-mb");
  }
}
@include media-breakpoint-up(xs1) {
  .grup-970 {
    left: calc(50vw - 0.5*(375/16)*1rem);
    width: (375/16)*1rem;
    height: 0.2*(375/16)*1rem;
  }
  .cover-shape-155 {
    top: -250px;
    width: (376/16)*1rem;
    height: (376/16)*1rem;
  }
  .grup-971 {
    top: -0.9px;
    left: calc(50vw - 0.5*(376/16)*1rem);
    width: (376/16)*1rem;
    height: 0.2*(376/16)*1rem;
  }
}
.cta-title {
  max-width: 25rem;
  margin: 0 auto;
}
.cta-section {
  position: relative;
}
.cta-section-text {
  position: relative;
  z-index: 1200;
}
.grup-967 {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.cover-shape-136-2 {
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  background-color: #C8D400;
  z-index: 840;
}
.cover-shape-137-2 {
  bottom: 50vw;
  left: calc(50vw - 0.5*300vw);
  width: 300vw;
  height: 200vw;
  transform: rotate(45deg) translate(10vw, 0vw);
  background: linear-gradient(to right, #52AE32 0%, #C8D400 85%);
  z-index: 850;
  opacity: 1;
}
.cover-shape-135-2 {
  bottom: 140vw;
  left: calc(50vw - 0.5*300vw);
  width: 300vw;
  height: 100vw;
  transform: rotate(76deg) translate(80vw, -44vw);
  background: linear-gradient(to bottom right, #52AE32 0%, #007A3D 100%);
  z-index: 860;
  opacity: 0.5;
}
@include media-breakpoint-up(sm) {
  .cover-shape-135-2 {
    transform: rotate(76deg) translate(80vw, -34vw);
  }
}
@include media-breakpoint-up(lg) {
  .cover-shape-137-2 {
    transform: rotate(18deg) translate(0vw, 40vw);
  }
  .cover-shape-135-2 {
    transform: rotate(48deg) translate(10vw, -44vw);
    bottom: 10vw;
  }
}

@include media-breakpoint-up(lg) {
  .market-home {
    &.market-home-right {
      margin-left: 3 * $spacer;
    }
    &.market-home-left {
      margin-right: 3 * $spacer;
      text-align: right;
    }
  }
}

// Faq
// ------------------------
.faq-accordion  {
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
  & button {
    padding-right: 2 * $spacer;
    position: relative;
    width: 100%;
    .faq-action {
      position: absolute;
      top: 4px;
      right: 0;
    }
    .faq-action.faq-action-close {
      right: 3px;
    }
  }
  & button[aria-expanded="false"] .faq-action-close  {
    display: none;
  }
  & button[aria-expanded="true"] .faq-action-open  {
    display: none;
  }
  .faq-content {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
}

// Site footer navigation
// ----------------------------
.site-footer-navigation,
.site-footer-navigation h4 {
  font-family: Gotham-Bold;
  font-size: 0.875rem;
  line-height: 1.625;
}
.site-footer-navigation {
  font-family: Gotham-Light;
}
.site-footer-navigation h4 {
  border-bottom: 3px solid theme-color("secondary");
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}

// Multi step form
// ----------------------------
.msf {
  opacity: 0;
  transition: opacity 0.7s ease-in;
}
.msf-view {
  opacity: 0;
  transition: opacity 0.7s ease-in;
}

.msf-view,
.msf-navigation {
    display: none;
}
.msf-navigation {
    margin-top: 12px;
    margin-bottom: 12px;
}

// User area
// -------------------------
.layout-public.login-form {
  .mobile-menu.link-accedir,
  .mobile-menu .link-accedir {
    display: none;
  }
}
.container-fluid.login-wrap,
.container-fluid.user-area {
  position: relative;
}
.container-fluid.login-wrap {
  h2,
  .form-group {
    z-index: 1200;
    color: #fff;
    position: relative;
  }
}
.btn.btn-link.btn-mb {
  color: #000;
}
a.btn.btn-link.btn-mb:hover {
  text-decoration: underline;
}
.btn.btn-secondary.btn-lg.btn-block.btn-mb {
  text-transform: lowercase;
}
.container-fluid.user-area {
  .card-body {
    h1,
    .btn {
      z-index: 1200;
      position: relative;
    }
    h1 {
      color: #fff;
    }
    &.w-520 {
      max-width: (520/16)*1rem;
      margin: 0 auto;
    }
  }
}
.grup-975 {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.cover-shape-136-3 {
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  background-color: #C8D400;
  z-index: 840;
}
.cover-shape-137-3 {
  bottom: 50vw;
  right: calc(50vw - 0.5*300vw);
  width: 300vw;
  height: 200vw;
  transform: rotate(-45deg) translate(-20vw, 0vw);
  background: linear-gradient(to left, #52AE32 0%, #C8D400 85%);
  z-index: 850;
  opacity: 1;
}
.cover-shape-135-3 {
  bottom: 140vw;
  right: calc(50vw - 0.5*300vw);
  width: 300vw;
  height: 100vw;
  transform: rotate(-76deg) translate(-80vw, -44vw);
  background: linear-gradient(to bottom right, #52AE32 0%, #007A3D 100%);
  z-index: 860;
  opacity: 0.5;
}
@include media-breakpoint-up(sm) {
  .cover-shape-135-3 {
    transform: rotate(-76deg) translate-(80vw, -34vw);
  }
}
@include media-breakpoint-up(lg) {
  .cover-shape-137-3 {
    transform: rotate(-18deg) translate(0vw, 38vw);
    background: linear-gradient(to left, #52AE32 0%, #C8D400 85%);
  }
  .cover-shape-135-3 {
    transform: rotate(-48deg) translate(10vw, -44vw);
    background: linear-gradient(to bottom right, #52AE32 0%, #007A3D 100%);
    bottom: 10vw;
  }
}

.user-area {
  .btn.btn-secondary {
    display: block;
  }
}
.reservations-view {
  font-size: (12/16)*1rem;
  max-width: 16.5625rem;
  margin: 0 auto;
  td {
    vertical-align: top;
  }
  tr {
    border-bottom: 1px solid theme-color("dark");
    margin: (2* $spacer) 0;
    &:last-child {
      border-bottom: none;
    }
  }
}
.resum-totals-reserva {
  max-width: 17.5rem;
  margin: 0 auto;
}
@include media-breakpoint-up(lg) {
  .reservations-view {
    font-size: (14/16)*1rem;
  }
}

.mb-view-section {
  max-width: (380/16)*1rem;
  margin: 0 auto;
}

// Datepicker
// -------------------------

.date-picker-wrapper {
  max-width: 31.25rem;
  border-top: 1px solid;
}

@include media-breakpoint-up(xl) {
  .date-picker-wrapper {
    border-top: 0px;
  }
  .dates-xl-separator {
    border-left: 1px solid;
  }
}

.datepicker {
  border: 1px solid;
}

.mandatory-dates {
  display: none;
  border-radius: 3px;
  padding: 8px;
  margin-top: 16px;
  margin-bottom: 16px;
  border-color: red;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.075), 0 0 8px rgba(255, 0,0,0.6);
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.075), 0 0 8px rgba(255, 0,0,0.6);
}

.form-group.dates-group {
  margin: 0 -15px 0;
}

.datepicker.datepicker-inline {
  width: 100%;
  padding: 0;
  //box-sizing: content-box;
  //border: 2px solid #000;
  .datepicker-days {
      td, th {
          width: calc( 0.14 * ( 100vw - 0 * 36px ) );
      }
  }
}

@media screen and (min-width: 375px) {
  .datepicker {
    .datepicker-days {
      td, th {
        width: 49px;
      }
    }
  }
}

// Magnific popup
// ----------------------------
.mfp-close {
    color: black;
}
/* overlay at start */
.mfp-fade.mfp-bg {
  opacity: 0;
  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
/* overlay animate in */
.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8;
}
/* overlay animate out */
.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}

/* content at start */
.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;

  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
/* content animate it */
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}
/* content animate out */
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}
.reserva-espais-popup-link,
.reserva-espais-ajax-popup-link {
    display: none;
}
.reserva-espais-popup,
.reserva-espais-ajax-popup {
    position: relative;
    background-color: #fff;
    padding: 20px;
    width: auto;
    max-width: 500px;
    margin: 0 auto;
}

// Form validation
// ----------------------------
.input-validation-error {
    border-color: red;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.075), 0 0 8px rgba(255, 0,0,0.6);
    box-shadow: inset 0 1px 1px rgba(0,0,0,0.075), 0 0 8px rgba(255, 0,0,0.6);
}

.input-validation-error:focus {
    border-color: red;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.075), 0 0 8px rgba(255, 0,0,0.6);
    box-shadow: inset 0 1px 1px rgba(0,0,0,0.075), 0 0 8px rgba(255, 0,0,0.6);
}

.input-group-addon.separador-dates {
  margin-top: 7px;
}

// Resum reserva
// ----------------------------
.resum {
  list-style-type: none;
  padding-left: 0;
}

//.mb-title {
//  padding: 32px;
//  background-color: rgba(0,0,0,0.55);
//  border-radius: 5px;
//}
//.kokokokmain-cover-section::before{
//  background-color: rgba(0, 0, 0, 0.55);
//  content: '';
//  display: block;
//  height: 100%;
//  position: absolute;
//  width: 100%;
//}
