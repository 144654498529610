// Logo
// ----------------------------
.logo-logo {
    width: 32px;
  }
  .logo-mb {
    margin-bottom: 2px;
    & img {
      height: 9.49px;
    }
  }
  .logo-subtitle {
    font: normal 0.75rem/1rem Gotham-Light;
    margin-bottom: 1px;
  }
  
  .site-subtitle {
    display: none;
    @include media-breakpoint-up(xs2) {
      &::before {
        content: " | ";
      }
      display: inline;
      margin-left: 0.3 * $spacer;
    }
  }
  
  @include media-breakpoint-up(sm) {
    .logo-logo {
      width: 54px;
    }
    .logo-mb {
      margin-bottom: 3px;
      & img {
        height: 12.759px;
      }
    }
    .logo-subtitle {
      font: normal 1.0625rem/1rem Gotham-Light;
      margin-bottom: 0px;
      margin-left: 0.5 * $spacer;
    }
  }
  @include media-breakpoint-up(lg) {
    .logo-logo {
      width: 54px;
    }
    .logo-mb {
      margin-bottom: 3px;
      & img {
        height: 21.3px;
      }
    }
    .logo-subtitle {
      font: normal 1.8125rem/1rem Gotham-Light;
      margin-bottom: 0px;
      margin-left: 1 * $spacer;
    }
  }
  
  
  // Navbar
  // ----------------------------
  .navbar {
    overflow: hidden;
  }
  .navbar-toggler {
      border: none;
    }
  .nav-espais .navbar-nav {
      margin-left: auto;    
  }
  @include media-breakpoint-down(xs) {
    .navbar-brand {
      padding-bottom: 0;
      margin-right: 2rem;
    }
  }
  
  #overlay-navbar {
    color: $white;
    background-color: theme-color("primary");
    .active-lang-text,
    .active-lang-arrow {
      background-color: theme-color("light");
    }
    .site-subtitle,
    .nav-link {
      font-family: Gotham-Light;
    }
    display: none;
    height: 100%;
    left: 0;
    //padding: 0;
    //opacity: 0;
    position: fixed;
    top: 0;
    //transition: opacity 0.5s;
    width: 100vw;
    z-index: 1;
    .mobile-menu {
      padding-top: 10vh;
    }
    .site-footer {
      opacity: 1;
    }
  }
  
  // Forms
  // ----------------------
  .form-control {
    &::placeholder {
        text-align: center;
    }
  }
  
  // Login form
  // ----------------------------
  .header-login {
    .form-control {
      background-color: color("light-gray-bg");
    }
  }
  .login-action {
    text-transform: lowercase;
  }
  .gradient {
    position: relative;
    left: 0;
    
    &.totop {
      top: 0px;
      height: 32px;
      background-image: linear-gradient(to top, rgba(54, 39, 131, 0.1), rgba(54, 39, 131, 0));
    }
    &.tobottom {
      top: 0;
      height: 32px;
      background-image: linear-gradient(to bottom, rgba(54, 39, 131, 0.1), rgba(54, 39, 131, 0));
    }
  }
  .login-wrap {
    @media only screen and (orientation: landscape) {
      padding-top: 10vh;
      padding-bottom: 10vh;
    }
    @media only screen and (orientation: landscape) and (min-width: 992px) {
      padding-top: 18vh;
      padding-bottom: 18vh; 
    }
    @media only screen and (orientation: portrait) {
      padding-top: 8vh;
      padding-bottom: 8vh;
    }
    @media only screen and (orientation: portrait) and (min-width: 576px) {
      padding-top: 15vh;
      padding-bottom: 15vh;
    }
  }
  
  .login-form-wrap {
    max-width: 17em;
    margin: 0 auto;
    @include media-breakpoint-up(lg) {
      max-width: 23em;
    }
  }

  // Faqs
  // ----------------------------
  .faq-accordion .btn {
    text-align: left; 
  }

  // Site footer
  // ----------------------------
  .site-footer {
    color: theme-color("dark");
    background-color: theme-color("secondary");
    opacity: 0.8;
    z-index: 0;
  }
  
  .header-login .site-footer {
    position: absolute;
    top: calc( 100vh - 3.875rem);
  }
  
  .site-footer-content {
    font: normal 0.7rem/1.25rem $font-family-bold;
    padding: 1.3125rem 0;
  }
  .site-footer-content {
    .copyright-text,
    .legal,
    .legal a {
      font-family: $font-family-light;
      color: theme-color("dark");
    }
    .legal a {
      text-decoration: underline;
    }
  }
  @include media-breakpoint-up(xs2) {
    .site-footer-content {
      font-size: 0.875rem;
      //line-height: 1.25;
    }
  }
  
  #overlay-navbar {
    .site-footer {
      margin: 0 -1rem;
      position: absolute;
      top: calc( 100vh - 3.875rem);
      width: 100vw;
    }
    .site-footer-content {
      margin: 0 auto;
    }
  }
  .data-form {
    .site-footer {
      position: relative;
      top: 0;
    }
  }
  .mobile-menu {
    margin: 0 auto;
    .nav-link {
      font-size: 1rem;
    }
    .nav-item {
      border-bottom: 1px solid rgba(255,255,255, 0.2);
      //padding: 0.75rem 0;
    }
    .nav-item:last-child {
      border: 0;
    }
  }