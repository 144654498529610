.mw-310 {
    max-width: 19.375rem;
}

.responsive-img img {
    width: 100%;
}

.w-100-xs2 {
    @include media-breakpoint-down(xs2) {
        width: 100%;
    }
}

.w-50-md {
    width: 100%;
    @include media-breakpoint-up(md) {
        width: 50%;
    }
}

.w-50-lg {
    width: 100%;
    @include media-breakpoint-up(lg) {
        width: 50%;
    }
}

.wsnw {
    white-space: nowrap;
}

.mb-bold {
    font-family: $font-family-gotham-bold;
}
